
























import { Mixin } from "@/core/mixins/mixin";
import { Component, Mixins, Prop, Vue } from "vue-property-decorator";
import RealMobileBond from "@/views/Mine/Set/Account/Component/RealMobileBond.vue";
import { Row, Image, NavBar, Button } from "vant";
import { UserModule } from "@/store/modules/user";

@Component({
  components: {
    RealMobileBond,
    [Row.name]: Row,
    [Image.name]: Image,
    [NavBar.name]: NavBar,
    [Button.name]: Button,
  },
})
export default class checkUse extends Mixins(Mixin) {
  public code = "";
  onClickLeft(): void {
    this.$router.go(-1);
  }
  getCode(code: string): void {
    this.code = code;
  }
  goSetPassword(): void {
    this.$api.memberApi.eWallet.identity(
      {
        code: this.code,
        scene: "IDENTITY",
        phone: UserModule.phone,
      },
      ({ data }) => {
        if (data) {
          this.$router.push(`/set-password/${data}`);
        }
      }
    );
  }
}
