
































































import { Mixin } from "@/core/mixins/mixin";
import { Component, Mixins } from "vue-property-decorator";
import {
  NavBar,
  Cell,
  CellGroup,
  Image,
  Field,
  Icon,
  Row,
  Col,
  Button,
  List,
} from "vant";
@Component({
  components: {
    Mixin,
    [NavBar.name]: NavBar,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Image.name]: Image,
    [Field.name]: Field,
    [Icon.name]: Icon,
    [Row.name]: Row,
    [Col.name]: Col,
    [Button.name]: Button,
    [List.name]: List,
  },
})
export default class walletApplyCash extends Mixins(Mixin) {
  public text = "";
  public form = {
    amount: "",
    name: "",
    bankName: "",
    cardCode: "",
  };
  public allAmount: string | (string | null)[] = "";
  public extract = false;
  public CashCount = 0;
  get checkForm(): boolean {
    return (
      Number(this.form.amount) !== 0 &&
      this.form.name !== "" &&
      this.form.bankName !== "" &&
      this.form.cardCode !== ""
    );
  }

  created(): void {
    this.extract = this.$route.query.extract === "true" ? true : false;
    if (this.extract) {
      this.getExtractDetail();
    } else {
      this.getCashCount();
    }

    this.getTipsText();
  }
  onClickLeft(): void {
    this.$router.go(-1);
  }
  checkAmount(): void {
    if (
      Number(this.form.amount) === 0 ||
      Number(this.form.amount) > this.CashCount
    ) {
      this.$toast("金额填写错误。余额大于0且小于等于直充金额！");
    }
  }
  // 查看用户提现明细
  getExtractDetail(): void {
    this.$api.memberApi.eWallet.getExtractDetail(({ data }) => {
      this.form.amount = String(data.extractMoney!);
      this.form.cardCode = data.cardNum!;
      this.form.name = data.name!;
      this.form.bankName = data.bankName!;
    });
  }
  // 获取当前用户可以提现额度
  getCashCount(): void {
    this.$api.memberApi.eWallet.getDirectlyAmount(({ data }) => {
      this.CashCount = data;
    });
  }
  submit(): void {
    this.$api.memberApi.eWallet.saveWalletExtractApply(
      {
        bankName: this.form.bankName,
        cardNum: this.form.cardCode,
        extractMoney: Number(this.form.amount),
        name: this.form.name,
      },
      ({ data }) => {
        if (data) {
          this.extract = true;
          this.$router.go(-1);
        }
      }
    );
  }
  getTipsText(): void {
    this.$api.cmsApi.commonInfo.policy("WITHDRAWAL_DESC", ({ data }) => {
      this.text = data;
    });
  }
}
