




















import { Component, Vue } from "vue-property-decorator";
import CancelPayment from "@/components/CommonPayment/CancelPayment.vue";
import RechargeSuccess from "@/views/Mine/AssetCenter/ElectronicWallet/Components/RechargeSuccess.vue";

@Component({
  components: {
    CancelPayment,
    RechargeSuccess,
  },
})
export default class RechargeResult extends Vue {
  resultStatus: "LOADING" | "SUCCESS" | "CANCLE" = "LOADING";
  totalAmount = "0";
  billNo = ""; // 支付订单号，取消支付时要用
  orderId = ""; // 支付订单号，取消支付时要用
  payMethod = ""; // 支付方式

  created(): void {
    this.resultStatus = "LOADING";
    let query = this.$route.query;
    let payMethod = query.payMethod;
    if (
      (payMethod == "WXPAY" && query.status == "WAIT_BUYER_PAY") ||
      (payMethod == "ALIPAY" && query.status == "NEW_ORDER")
    ) {
      this.payMethod = payMethod;
      this.billNo = query.billNo ? String(query.billNo) : "";
      this.orderId = query.orderId ? String(query.orderId) : "";
      /**
       * 微信支付，用户点击了取消支付：payMethod == "WXPAY" && query.status == "WAIT_BUYER_PAY"
       * 支付宝支付，用户点击了取消支付：payMethod == "ALIPAY" && query.status == "NEW_ORDER"
       * 此时展示取消支付组件
       */
      this.resultStatus = "CANCLE";
    } else if (query.status == "TRADE_SUCCESS") {
      // 其他支付类型，且支付成功
      this.totalAmount = String(Number(query.totalAmount) / 100);
      this.resultStatus = "SUCCESS";
    }
  }

  closeSuccess(): void {
    this.$router.replace("/electronicWallet/index");
  }
}
