










import { Mixin } from "@/core/mixins/mixin";
import { Component, Mixins } from "vue-property-decorator";
import NotOpen from "./WalletNotOpen.vue";
import Opened from "./WalletOpend.vue";
@Component({
  components: { Mixin, NotOpen, Opened },
})
export default class Index extends Mixins(Mixin) {
  public isRealName = false; // 当前用户是否实名制
  public isOpen = true; // 当前用户是否开通余额
  walletInfo = {};
  created(): void {
    this.getEleInfos();
  }
  refreshPage(): void {
    this.getEleInfos();
  }
  getEleInfos(): void {
    this.$api.memberApi.eWallet.getEWalletInfo(({ data }) => {
      this.isOpen = data.open!;
      this.isRealName = data.identity!;
      let dataObj = {
        balance: String(data.balance!),
        phone: data.phone!,
        freeze: data.freeze!,
        extract: data.extract!,
      };
      this.walletInfo = dataObj;
      let walletOpen = this.$refs["wallet-open"] as Opened;
      this.$nextTick(() => {
        if (walletOpen) {
          walletOpen.getData(dataObj);
        }
      });
    });
  }
}
