














import { Mixin } from "@/core/mixins/mixin";
import { Component, Mixins, Prop } from "vue-property-decorator";
import { Dialog } from "vant";
@Component({
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
})
export default class WalletOpend extends Mixins(Mixin) {
  @Prop({ default: false })
  public showactdesc?: boolean;
  @Prop({ default: "" })
  public title!: string;
  @Prop({ default: "", type: String }) public desc!: string;
  public content = "";

  mounted() {
    this.getInfo();
  }
  getInfo(): void {
    let type = "TIPS_USING";
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.$api.cmsApi.commonInfo.policy(type as any, ({ data }) => {
      // this.content = data;
      this.content = data!.replace(
        /<img/g,
        "<img style='max-width:100%;height:auto;'"
      );
    });
  }
  confirm() {
    this.$emit("closeNotice");
  }
}
