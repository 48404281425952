



















































import { Mixin } from "@/core/mixins/mixin";
import { Component, Mixins, Prop, Vue, Watch } from "vue-property-decorator";
import {
  NavBar,
  Cell,
  CellGroup,
  Image,
  Field,
  Icon,
  Row,
  Col,
  Button,
  List,
  PasswordInput,
  NumberKeyboard,
} from "vant";
@Component({
  components: {
    Mixin,
    [NavBar.name]: NavBar,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Image.name]: Image,
    [Field.name]: Field,
    [Icon.name]: Icon,
    [Row.name]: Row,
    [Col.name]: Col,
    [Button.name]: Button,
    [List.name]: List,
    [PasswordInput.name]: PasswordInput,
    [NumberKeyboard.name]: NumberKeyboard,
  },
})
export default class OpenWallet extends Mixins(Mixin) {
  uuid = "";
  currentKey = "password";
  password = "";
  checkPassword = "";
  showKeyboard = false;
  created(): void {
    this.uuid = this.$route.params.uuid;
  }
  onClickLeft(): void {
    this.$router.go(-1);
  }
  onInput(key: string): void {
    if (this.currentKey === "password") {
      this.password = (this.password + key).slice(0, 6);
    } else {
      this.checkPassword = (this.checkPassword + key).slice(0, 6);
    }
  }

  onDelete(): void {
    if (this.currentKey === "password") {
      this.password = this.password.slice(0, this.password.length - 1);
    } else {
      this.checkPassword = this.checkPassword.slice(
        0,
        this.checkPassword.length - 1
      );
    }
  }
  confirmPassword(): boolean {
    return this.password.length === 6 && this.password === this.checkPassword;
  }
  changeKeyboard(currentKey: string): void {
    this.currentKey = currentKey;
    this.showKeyboard = true;
  }
  setPassWord(): void {
    if (!this.confirmPassword()) {
      this.$toast("两次密码输入不一致，请重新输入");
    } else {
      this.$api.memberApi.eWallet.setPassword(
        {
          password: this.password,
          scene: "PASSWORD",
          uuid: this.uuid,
        },
        (data) => {
          if (data.data) {
            this.$toast("密码设置成功");
            this.$router.push("/electronicWallet/index");
          }
        }
      );
    }
  }
}
